import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Container,
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Button,
} from "reactstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";

const Resume = (props) => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);

  const [proPorVencer, setProPorVencer] = useState([]); // etiqueta pro y vencimiento en 7 días
  const [proVencidos, setProVencidos] = useState([]); // etiqueta pro y vencidos hace manos de 7 días
  const [freeVencidos, setFreeVencidos] = useState([]); // etiqueta free y vencidos hace manos de 7 días con mas de 50 tickets
  const [freePorComprar, setFreePorComprar] = useState([]); // etiqueta con licencia por vencer en menos de 7 días y mas de 50 tickets

  const goToCompany = (c) => {
    navigate(`/dashboard/${c.id}`);
  };

  useEffect(() => {
    if (!props.companies) return;

    console.log("Cantidad de companies", props.companies.length);
    // Arrays temporales para acumular los datos
    const tempProPorVencer = [];
    const tempProVencidos = [];
    const tempFreeVencidos = [];
    const tempFreePorComprar = [];

    const companies_list = sessionStorage.getItem("companies_list");

    // PARA PRO VENCIDOS
    if (companies_list) {
      const listado = JSON.parse(companies_list);
      console.log("listado", listado);
      listado.forEach((company) => {
        const licenceUntilDate = company.licence
          ? moment(company.licence.untildate, "YYYY-MM-DD")
          : false;
        const daysUntilLicence = licenceUntilDate
          ? -1 * moment().diff(licenceUntilDate, "days")
          : false;

        const isPro = company.managerData && company.managerData.isPro === true;
        const vencido = daysUntilLicence < 0;

        if (isPro && daysUntilLicence < 0) {
          
          const data = {
            ...company.company,
            id: company.id,
            managerData: company?.managerData,
            licence: company?.licence,
          };
          
          tempProVencidos.push(data);
        }
      });
    }

    console.log("x companies", props.companies);
    // Iterar sobre las compañías y clasificarlas
    props.companies.forEach((company) => {
      const licenceUntilDate = company.licence
        ? moment(company.licence.untildate, "YYYY-MM-DD")
        : false;
      const daysUntilLicence = licenceUntilDate
        ? -1 * moment().diff(licenceUntilDate, "days")
        : false;

      const isPro = company.managerData && company.managerData.isPro === true;
      // const seguimiento = company.managerData && company.managerData.sg === true; // Si es necesario
      const vencido = daysUntilLicence < 0;

      // Agregamos la propiedad daysUntilLicence a la compañía
      company.daysUntilLicence = daysUntilLicence;

      if (isPro && daysUntilLicence >= 0 && daysUntilLicence < 7 && !vencido) {
        tempProPorVencer.push(company);
      }

      if (!isPro && daysUntilLicence < 0 && company.ticketsCount > 50) {
        console.log("VENCIDO FREE", company);
        tempFreeVencidos.push(company);
      }

      if (
        !isPro &&
        daysUntilLicence < 15 &&
        daysUntilLicence >= 0 &&
        company.ticketsCount > 50
      ) {
        tempFreePorComprar.push(company);
      }
    });

    // Ordenar cada array según daysUntilLicence
    tempProPorVencer.sort((a, b) => a.daysUntilLicence - b.daysUntilLicence);
    tempProVencidos.sort((a, b) => a.daysUntilLicence - b.daysUntilLicence);
    tempFreeVencidos.sort((a, b) => a.daysUntilLicence - b.daysUntilLicence);
    tempFreePorComprar.sort((a, b) => a.daysUntilLicence - b.daysUntilLicence);

    // Actualizar el estado una sola vez para cada uno
    setProPorVencer(tempProPorVencer);
    setProVencidos(tempProVencidos);
    setFreeVencidos(tempFreeVencidos);
    setFreePorComprar(tempFreePorComprar);
  }, [props.companies]);

  document.title = "Cuentas | TicketControl - Admin";
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Resumen" breadcrumbItem="Resumen" />

          {/* Pro Por Vencer */}
          <p>
            Todos los resultados mostrados son usuarios que se han logeado al
            menos hace tres días
          </p>

          {proPorVencer.length > 0 && (
            <Card>
              <CardBody>
                <CardTitle>
                  <h3>PRO por Vencer ({proPorVencer.length})</h3>
                </CardTitle>
                <CardText>
                  Clientes PRO que vencerán en los próximos 7 días
                </CardText>
                <Row className="g-1 gy-0 mt-3">
                  {proPorVencer.map((customer, key) => (
                    <Col xs={"auto"} key={"_customer2_" + key}>
                      <Item company={customer} goToCompany={goToCompany} />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}

          {/* Pro Vencidos */}

          {proVencidos.length > 0 && (
            <Card>
              <CardBody>
                <CardTitle>
                  <h3>PRO Vencidos ({proVencidos.length})</h3>
                </CardTitle>
                <CardText>
                  Clientes PRO que vencieron hace más de 7 días
                </CardText>
                <Row className="g-1 gy-0 mt-3">
                  {proVencidos.map((customer, key) => (
                    <Col xs={"auto"} key={"_customer3_" + key}>
                      <Item company={customer} goToCompany={goToCompany} />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}

          {/* Free Vencidos */}
          {freeVencidos.length > 0 && (
            <Card>
              <CardBody>
                <CardTitle>
                  <h3>Free Vencidos ({freeVencidos.length})</h3>
                </CardTitle>
                <CardText>
                  Usuarios Free que tienen al menos 50 tickets realizados cuya
                  licencia ha vencido en estos últimos 7 días.
                </CardText>
                <Row className="g-1 gy-0 mt-3">
                  {freeVencidos.map((customer, key) => (
                    <Col xs={"auto"} key={"_customer1_" + key}>
                      <Item company={customer} goToCompany={goToCompany} />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}

          {/* Free Por Comprar */}

          {freePorComprar.length > 0 && (
            <Card>
              <CardBody>
                <CardTitle>
                  <h3>
                    Free con potencial para Comprar ({freePorComprar.length})
                  </h3>
                </CardTitle>
                <CardText>
                  Clientes Free que tienen al menos 50 tickets realizados cuya
                  licencia vence en menos de 15 días.
                </CardText>
                <Row className="g-1 gy-0 mt-3">
                  {freePorComprar.map((customer, key) => (
                    <Col xs={"auto"} key={"_custome5_" + key}>
                      <Item company={customer} goToCompany={goToCompany} />
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
};

const Item = ({ company, goToCompany }) => {
  // const dateInstall = company.dateInstall ? moment(company.dateInstall, 'YYYY-MM-DD') : false;
  // const dateInstallToText = dateInstall ? dateInstall.format('DD/MM/YYYY') : ''
  const licenceUntilDate = company.licence
    ? moment(company.licence.untildate, "YYYY-MM-DD")
    : false;
  // const licenceUntilDateToText = licenceUntilDate ? licenceUntilDate.format('DD/MM/YYYY') : ''
  const daysUntilLicence = licenceUntilDate
    ? -1 * moment().diff(licenceUntilDate, "days")
    : false;
  // const diffDays = dateInstall && licenceUntilDate ? licenceUntilDate.diff(dateInstall, 'days') : false;
  //  const licenceDiffDays = props.licence.diffDays
  const isPro = company.managerData && company.managerData?.isPro == true;
  const seguimiento = company.managerData && company.managerData?.sg == true;

  const vencido = daysUntilLicence < 0 ? true : false;
  const ticketsCount = company.ticketsCount;
  return (
    <div
      style={{
        width: 350,

        borderLeft: seguimiento ? "20px solid orange" : "",
      }}
      onClick={() => {
        goToCompany(company);
      }}
      className={`button-content btn flex  waves-effect waves-light btn-md ${
        isPro == false ? "btn-info" : "btn-success"
      } ${
        daysUntilLicence < 0
          ? "btn-dark"
          : daysUntilLicence < 16
          ? "btn-danger"
          : ""
      }`}
      st="width:auto"
    >
      <Row className="text-start">
        <Col>
          <b>{company.name ? company.name : "NOMBRE NO DISPONIBLE"}</b>
          {isPro && (
            <>
              {" "}
              <Badge color="info">PRO</Badge>
            </>
          )}
        </Col>
        <Col className="text-end">
          <Badge color="secondary" size="md">
            D: {daysUntilLicence}
          </Badge>
          <br />
          <Badge color="secondary" size="md">
            T: {ticketsCount}
          </Badge>
        </Col>
      </Row>
      <Row className="text-start">
        <Col>
          {company.country && company.city ? (
            <div style={{ color: "white", fontSize: 12 }}>
              {company.city.toUpperCase()} ● {company.country.toUpperCase()} ●
              DB:
              {company.versionDB} ● Key {company.id}
            </div>
          ) : (
            "DATOS NO DISPONIBLES"
          )}
        </Col>
      </Row>
    </div>
  );
  // return (<Button style={{ minWidth: 400 }} type="button" className={`btn ${isPro ? 'btn-success' : vencido ? '' : diffDays == false ? 'btn-danger' : 'btn-info'}   flex  waves-effect waves-light btn-sm`} onClick={() => { ChangeCompany(company) }}>{company.name} ({daysUntilLicence}) - {company.versionDB} {company.managerData && company.managerData?.isPro == true ? 'PRO' : ''}</Button>
  // )
};

const mapStateToProps = (state) => {
  return {
    companies: state.companies.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
