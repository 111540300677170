import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";

//constants
import { layoutTypes } from "../constants/layout";

// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
import HorizontalLayout from "../Layout/HorizontalLayout/index";
import { AuthProtected } from "./AuthProtected";

import { authProtectedRoutes, publicRoutes } from "./routes";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  where,
  query,
  collectionGroup,
  orderBy,
  setDoc,
  updateDoc,
  getCountFromServer,
} from "firebase/firestore";
import { firestore, old_firestore } from "../Firebase/firebaseConfig";
import { setDataCompanies } from "../store/companies/actions";
import ListenerDB from "./ListenerDB";
import { last, random } from "lodash";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = () => {
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem('authUser'))
    // if (user && user.email) {
    //   setEmail(user.email)
    // }
  }, []);

  const Layout = getLayout(layoutType);

  return (
    <Routes>
      <Route>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      <Route>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <CheckCompanies email={"email"}>
                  <ListenerDB />
                  <Layout>{route.component}</Layout>
                </CheckCompanies>
              </AuthProtected>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Route>
    </Routes>
  );
};

const CheckCompanies = (props) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("authUser"));

  const getEmptyAppConfig = async () => {
    const usersCollectionRef = collection(firestore, "users");
    const q = query(usersCollectionRef);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((document) => {
      const key = document.id;

      const data = document.data();

      if (data.app_config == undefined || data.licence == undefined) {
        const customerDocRef = doc(old_firestore, "customers", key);
        getDoc(customerDocRef).then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const docData = docSnapshot.data();

            // APPCONFIG
            if (data.app_config == undefined) {
              const app_config = docData.appConfigData;
              if (app_config !== undefined) {
                const app_config_doc_ref = doc(firestore, "users", key);
                updateDoc(
                  app_config_doc_ref,
                  { app_config: app_config },
                  { merge: true }
                );
              }
            }

            // LICENCE
            if (data.licence == undefined) {
              const licence = docData.licence;
              if (licence !== undefined) {
                const licence_doc_ref = doc(firestore, "users", key);
                updateDoc(
                  licence_doc_ref,
                  { licence: licence },
                  { merge: true }
                );
              }
            }
          }
        });
      }
    });
  };

  // // BUSCA LOS USERS QUE NO TIENEN APPCONFIG Y LOS BUSCA EN LA VIEJA BASE DE DATOS PARA COPIARLOS A LA NUEVA
  // useEffect(() => {
  //   console.log('UPDATE FROM OLD FIRESTORE READY')
  //   getEmptyAppConfig()
  // }, [])

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

        const resultList = [];
        const companyMap = new Map();
        const authCollectionRef = collectionGroup(firestore, "logins");

        const q = query(
          authCollectionRef,
          where("created", ">=", threeDaysAgo.toISOString()),
          orderBy("created", "desc")
        );

        const querySnapshot = await getDocs(q);
        const promises = [];

        querySnapshot.forEach((loginDoc) => {
          const userDocRef = loginDoc.ref.parent.parent; // doc "users/..."
          if (!userDocRef) return;

          const promise = (async () => {
            const userDocSnapshot = await getDoc(userDocRef);
            if (!userDocSnapshot.exists()) return;

            const userData = userDocSnapshot.data();
            // 1. Usar getCountFromServer para contar tickets
            if (userData) {
              try {
                const ticketsCollRef = collection(userDocRef, "tickets");
                const countSnapshot = await getCountFromServer(ticketsCollRef);
                const ticketsCount = countSnapshot.data().count;
                userData.ticketsCount =  ticketsCount || -1;
              } catch (error) {
                console.log("error", error);
                userData.ticketsCount = -1;
              }
            } else {
              userData.ticketsCount = -1;
            }

            // 2. Obtener / ajustar la info de la compañía
            let company = userData.company;
            if (!company) {
              if (userData.app_config?.company) {
                company = userData.app_config.company;
              } else {
                company = {
                  name: "--- Sin Asignar ---",
                  address: "null",
                  city: "null",
                  country: "null",
                  phone: "null",
                  email: "null",
                  state: "null",
                  ticketsCount: 0,
                };
              }
            }

            if (company) {
              // Evitamos duplicar la misma compañía en el Map
              if (!companyMap.has(userDocRef.id)) {
                const dateInstall =
                  userData.app_config?.localVariables?.dateInstall || null;
                const lastLogin = userData.lastLogin || null;
                const versionDB = userData.app_config?.version_db || "0.0.0";
                const managerData = userData.managerData || {};

                company = {
                  ...company,
                  id: userDocRef.id,
                  licence: userData.licence,
                  dateInstall,
                  lastLogin,
                  versionDB,
                  managerData,
                  // Añadimos el conteo
                  ticketsCount: userData.ticketsCount,
                };

                companyMap.set(company.id, company);
              }
            }

            resultList.push(userDocRef.id);
          })();

          promises.push(promise);
        });

        await Promise.all(promises);

        // Convertimos el Map a array
        const companies = Array.from(companyMap.values());

        setList(resultList);
        dispatch(setDataCompanies(companies));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();

   

  }, []);

  

  if (loading) {
    return <Loading />;
  }

  if (list.length === 0) {
    return <NoCompany />;
  } else {
    return <>{props.children}</>;
  }
};

export default Index;

const Loading = () => {
  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="text-center">
                  <div>
                    <h4 className="text-uppercase mt-4">Cargando...</h4>
                    <p>Estamos recopilando la información</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const NoCompany = () => {
  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="text-center">
                  <div>
                    <h4 className="text-uppercase mt-4">
                      No hay Estacionamiento asignado a esta cuenta
                    </h4>
                    <p>
                      Para autorizarte, debes ingresar a TicketControl y
                      autorizar tu email. Ingresa en Configuración -> Ajustes ->
                      Monitor.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
