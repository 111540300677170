import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import moment from 'moment';
import { Button, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import {
  collection,
  doc,
  updateDoc,
  Timestamp,
    query,
    where, 
    getDocs,
    writeBatch
} from "firebase/firestore";
import {firestore_licenses as firestore } from "../../Firebase/firebaseConfig";

const TableOrders = ({ orders }) => {

    console.log('************ orders', orders);
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-AR', {
            // style: 'currency',
            minimumFractionDigits: 0
        }).format(value);
    };


    

    const getStatusBadge = (status) => {
        switch (status) {
            case 'approved':
                return <h4><Badge color="success" size='large'>Aprobado</Badge></h4>;
            case 'pending':
                return <h4><Badge color="warning">Pendiente</Badge></h4>;
            case 'rejected':
            case 'cancelled':
                return <h4><Badge color="danger">Cancelado</Badge></h4 >;
            default:
                return <h4><Badge color="secondary">{status}</Badge></h4>;
        }
    };

    const columns = [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "80px",
      },
      {
        name: "Cliente",
        selector: (row) => row.user,
        cell: (row) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* Icono de bandera */}
            <FlagIcon
              hidden={row.status !== "approved"}
              processed={row.processed == true || false}
              orderId={row.id}
            />
            <span style={{ marginLeft: "8px" }}>{row.user}</span>
          </div>
        ),
        sortable: true,
      },
      {
        name: "Fecha",
        selector: (row) =>
          row.createdAt
            ? row.createdAt.toDate
              ? moment(row.createdAt.toDate()).format("YYYY-MM-DD")
              : moment(row.createdAt).format("YYYY-MM-DD")
            : "-",
        sortable: true,
        width: "150px",
      },
      {
        name: "Duración",
        selector: (row) => row.duration,
        sortable: true,
        width: "150px",
        justifyContent: "center",
      },
      {
        name: "Método de Pago",
        selector: (row) => row.paymentMethod,
        sortable: true,
      },
      {
        name: "Total",
        selector: (row) => row.currency + " " + formatCurrency(row.total),
        sortable: true,
      },
      {
        name: "Estado",
        selector: (row) => getStatusBadge(row.status),
        sortable: true,
        width: "150px",
      },
      {
        name: "Acciones",
        cell: (row) => (
          <Link to={`/orders/${row.id}`}>
            <Button block color="primary" size={"sm"}>
              Ver
            </Button>
          </Link>
        ),
        button: true,
      },
    ];

    const data = orders.map(order => ({
        id: order.id,
        user: order.user,
        createdAt: order.createdAt,
        total: order.total,
        status: order.status,
        currency: order.currency,
        paymentMethod: order.paymentMethod,
        duration: order.duration,
        processed: order.processed
       
    }));

    const tableData = {
        columns,
        data
    };

    return (
        <DataTableExtensions {...tableData} exportHeaders={false} filter={false} export={false} print={false}>
            <DataTable
                title="Órdenes"
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                paginationPerPage={25}
                paginationRowsPerPageOptions={[25, 50, 100]}
            />
        </DataTableExtensions>
    );
};


// Componente para el icono de bandera que cambia de color al hacer click
const FlagIcon = ({ processed, hidden, orderId }) => {
  const [flagged, setFlagged] = useState(processed || false);

  const toggleFlag = async () => {
    try {
      // Invertimos el valor actual:
      const newValue = !flagged;
      // Actualizamos Firestore:
      await updateDoc(doc(firestore, "orders", orderId), {
        processed: newValue,
      });
      // Actualizamos el estado local para cambiar el color del ícono:
      setFlagged(newValue);
    } catch (error) {
      console.error("Error actualizando el campo 'processed':", error);
    }
  };

  if (hidden) return null;

  return (
    <span
      onClick={toggleFlag}
      style={{
        fontSize: 18,
        cursor: "pointer",
        color: flagged ? "green" : "#ddd",
      }}
    >
      <i className="fa fa-check-square"></i>
    </span>
  );
};


export default TableOrders;
