import React from "react";
import { Navigate, Route } from "react-router-dom";

import { useProfile } from "../Hooks/UserHooks";

const emailsAuthorized = ['dibarral@gmail.com', 'ticketcontrol.latam@gmail.com'] ////

const AuthProtected = (props) => {
  const { userProfile, loading } = useProfile();

  /*
    redirect is un-auth access protected routes via url
    */

    const isAuthorized = emailsAuthorized.includes(userProfile?.email)

  if (!userProfile && loading && isAuthorized) {
    
    
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<Component {...props} />);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
