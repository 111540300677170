import React, { useState, useEffect, useMemo, version } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { map } from "lodash";
import numeral from "numeral";
import moment from "moment";
import { Button, Badge } from "reactstrap";
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  where,
  query,
  collectionGroup,
  orderBy,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../Firebase/firebaseConfig";
moment.locale("es");

const TableCustomers = (props) => {
  // const changePro = async (id, state) => {
  //     try {
  //         const userRef = doc(firestore, "users", id);
  //         const userDoc = await getDoc(userRef);

  //         if (userDoc.exists()) {
  //             await setDoc(userRef, {
  //                 managerData: {
  //                     ...userDoc.data().managerData,
  //                     isPro: !state
  //                 }
  //             }, { merge: true });

  //             console.log('Campo managerData actualizado exitosamente');
  //         } else {
  //             console.log('El documento no existe');
  //         }
  //     } catch (error) {
  //         console.error('Error al actualizar el campo managerData:', error);
  //     }

  // }

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">ID</span>,
      selector: (row) => row.id,
      sortable: true,
      // width: "90px",
      cellExport: (row) => row.id,
    },
    {
      name: <span className="font-weight-bold fs-13">Tipo</span>,
      selector: (row) =>
        row.isPro == true && <Badge color={"success"}>PRO</Badge>,
      sortable: true,
      wrap: true,
      width: "90px",
      sortFunction: (a, b) => {
        const valueA = a.isPro ? 1 : 0;
        const valueB = b.isPro ? 1 : 0;
        return valueA - valueB;
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Parking</span>,
      selector: (row) => (
        <span style={{ fontWeight: 700 }}>
          {row.company_name.toUpperCase()}
        </span>
      ),
      sortable: true,
      wrap: true,
      width: "250px",
      cellExport: (row) => row.company_name,
    },
    {
      //address
      name: <span className="font-weight-bold fs-13">Dirección</span>,
      selector: (row) => row.address.toUpperCase(),
      sortable: true,
      wrap: true,
      // width: "250px",
      cellExport: (row) => row.address,
    },
    {
      // city
      name: <span className="font-weight-bold fs-13">Ciudad</span>,
      selector: (row) => row.city.toUpperCase(),
      sortable: true,
      wrap: true,
      // width: "250px",
      cellExport: (row) => row.city,
    },
    {
      // country
      name: <span className="font-weight-bold fs-13">País</span>,
      selector: (row) => row.country.toUpperCase(),
      sortable: true,
      wrap: true,

      cellExport: (row) => row.country,
    },
    {
      name: <span className="font-weight-bold fs-13">Última conexión</span>,
      selector: (row) =>
        row.last_conection
          ? moment(row.last_conection).format("YYYY-MM-DD HH:mm")
          : "-",
      sortable: true,
      wrap: true,
      sortFunction: (a, b) => {
        // Obtener las fechas originales
        a = a.last_conection;
        b = b.last_conection;

        // Si ambos son undefined, son iguales
        if (a === undefined && b === undefined) {
          return 0;
        }

        // Si a es undefined, debe ir al final en orden descendente
        if (a === undefined) {
          return -1;
        }

        // Si b es undefined, debe ir al final en orden descendente
        if (b === undefined) {
          return 1;
        }

        // Convertir las cadenas de fecha a objetos Date
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        return dateA - dateB; // Orden descendente
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Licencia Hasta</span>,
      selector: (row) =>
        row.licenceUntil ? moment(row.licenceUntil).format("YYYY-MM-DD") : "-",
      sortable: true,
      wrap: true,
      // width: "250px",
      sortFunction: (a, b) => {
        // Obtener las fechas originales
        a = a.licenceUntil;
        b = b.licenceUntil;

        // console.log('AAAA', typeof(a))
        // console.log('AAAA', a)

        // if a is not moment object, convert it
        if (!moment.isMoment(a)) {
          a = false;
        }

        // if b is not moment object, convert it
        if (!moment.isMoment(b)) {
          b = false;
        }

        // Si ambos son undefined, son iguales
        if (a === false && b === false) {
          return -1;
        }

        // Si a es undefined, debe ir al final en orden descendente
        if (a === false) {
          return -1;
        }

        // Si b es undefined, debe ir al final en orden descendente
        if (b === false) {
          return 1;
        }

        // if is Nan, return -1
        if (isNaN(a)) {
          return -1;
        }

        // if is Nan, return -1
        if (isNaN(b)) {
          return 1;
        }

        // Convertir las cadenas de fecha a objetos Date
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        const res = dateA - dateB;
        return res; // Orden descendente
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Version DB</span>,
      selector: (row) => row.versionDb,
      sortable: true,
      wrap: true,
      width: "100px",
      sortFunction: (rowA, rowB) => {
        // Aquí NO llega "sortDirection" directamente.
        // Lo leerás de donde lo tengas guardado (ej. un hook o props).
        // Supongamos que lo tienes en una variable sortDirection accesible.

        const verA = rowA.versionDb;
        const verB = rowB.versionDb;

        const isMissingA = !verA || verA === "-";
        const isMissingB = !verB || verB === "-";

        // Si AMBOS no tienen versión
        if (isMissingA && isMissingB) {
          return 0;
        }

        // Si SOLO A no tiene versión
        if (isMissingA && !isMissingB) {
          // Queremos que vaya al final tanto en asc como en desc
          // => Devolvemos +1 si sortDirection === 'asc' (A > B)
          // => También +1 si sortDirection === 'desc' (para que se quede abajo)
          return -1;
        }

        // Si SOLO B no tiene versión
        if (isMissingB && !isMissingA) {
          // Igual lógica, pero invertida
          return 1;
        }

        // Si AMBOS tienen versión, las comparamos numéricamente
        // separando por puntos para que 1.10 sea mayor que 1.2
        const partsA = verA.split(".").map((num) => parseInt(num, 10));
        const partsB = verB.split(".").map((num) => parseInt(num, 10));

        for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
          const numA = partsA[i] || 0;
          const numB = partsB[i] || 0;
          if (numA !== numB) {
            // sortDirection = 'asc' => numA - numB (típico)
            // sortDirection = 'desc' => podríamos devolver numB - numA
            // PERO la librería ya invierte al final.
            // Lo normal es devolver siempre numA - numB, y dejar que la librería invierta
            return numA - numB;
          }
        }

        return 0;
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Acciones</span>,
      selector: (row) => (
        <Link to={`/dashboard/${row.id}`}>
          <Button block color="primary" size={"sm"}>
            CARGAR
          </Button>
        </Link>
        // <Button onClick={() => { changePro(row.id, row.isPro) }}>CAMBIAR PRO</Button></>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  // a = { 'cc_id': 56, 'category_id': 2, 'timelapse_id': 8, 'closed': 0, 'user_id': 6, 'plate': 'DASD', 'brand': 'asdasd', 'cc_date_init': '2023-05-25 17:33:58.0000', 'cc_date_end': None, 'balance': -3387.1, 'next_movement': '2023-06-01 00:00:00', 'fullName': 'ssssssss aaaaaaa', 'phone': '', 'timelaspes_label': 'MENSUAL C/ DESC', 'timelapses_time': 43200, 'timelapse_tolerance': 0, 'category_name': 'Auto', 'rate_value': 15000.0, 'place_id': None, 'place_name': None, 'floor_name': None }

  let items = [];
  props.customers.forEach((customer, index) => {
    const licenceUntilDate = customer.licence
      ? moment(customer.licence.untildate, "YYYY-MM-DD")
      : false;
    // const daysTOlicenceUntilDate = licenceUntilDate ? moment().diff(licenceUntilDate, 'days') : false

    // const customer_type = daysTOlicenceUntilDate > 20 ? 'PRO' : 'FREE'

    const versionDB = customer?.app_config?.version_db ?? "-";
    // console.log('VERSIONDB', customer.app_config.version_db)

    // console.log('xxxxx', customer)
    let item = {
      id: customer["id"],
      // customer_type: customer_type,
      company_name: customer["company"] ? customer["company"]["name"] : "-",
      address: customer["company"] ? customer["company"]["address"] : "-",
      city: customer["company"] ? customer["company"]["city"] : "-",
      country: customer["company"] ? customer["company"]["country"] : "-",
      last_conection: customer["lastLogin"],
      licenceUntil: licenceUntilDate,
      versionDb: versionDB,
      isPro: customer["managerData"] ? customer["managerData"]["isPro"] : false,
      seguimiento: customer["managerData"]
        ? customer["managerData"]["sg"]
        : false,
    };

    // console.log('mmmmm', customer['managerData'])

    items.push(item);
  });

  const data = items;

  const tableData = {
    columns,
    data,
  };

  const customMessages = {
    // Mensajes del encabezado de la tabla
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver columnas",
      filterTable: "Filtrar tabla",
    },
    // Otros mensajes personalizados que desees cambiar
    // ...
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.isPro, // Si el usuario es PRO
      style: {
        backgroundColor: "#e0f2f1", // Verde claro
        //   color: "rgba(123,23,12,1)", // Texto oscuro
        //   fontWeight: "bold",
      },
    },

    {
      when: (row) => row.seguimiento, // Si el usuario es PRO
      style: {
        backgroundColor: "rgba(230, 120, 0, 0.4)", // Verde claro
        //   color: "rgba(123,23,12,1)", // Texto oscuro
        //   fontWeight: "bold",
      },
    },
  ];

  return (
    <div>
      {/* Input de búsqueda */}

      <DataTableExtensions
        {...tableData}
        filterPlaceholder="Filtrar"
        exportHeaders={false}
        filter={false}
        export={false}
        print={false}
      >
        <DataTable
          title={"Usuarios (" + String(data.length) + ")"}
          // columns={columns}
          data={data}
          pagination
          highlightOnHover
          paginationPerPage={25}
          className="data-table"
          paginationRowsPerPageOptions={[25, 50, 100, 500]}
          // noHeader={true}
          paginationDefaultPage={1}
          defaultSortFieldId={7}
          defaultSortAsc={false}
          paginationComponentOptions={{
            rowsPerPageText: "Filas por página:",
            rangeSeparatorText: "de",
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: "All",

            toolbar: {
              search: "Buscar",
              downloadCsv: "Descargar CSV",
              print: "Imprimir",
              viewColumns: "Ver columnas",
              filterTable: "Filtrar tabla",
            },
          }}
          customMessages={customMessages}
          // subHeader
          // subHeaderComponent={
          //     <FilterComponent
          //       onFilter={handleFilterChange}
          //       onClear={handleClear}
          //       filterText={filterText}
          //     />
          //   }
          // paginationResetDefaultPage={resetPaginationToggle}
          noDataComponent={<div className="p-4 text-center">No hay datos</div>}
          persistTableHead
          conditionalRowStyles={conditionalRowStyles} // Agregar los estilos condicionales
        />
      </DataTableExtensions>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TableCustomers);
