import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import Cash from "../Pages/Cash";
import Companies from "../Pages/_EMPTY";
import Tickets from "../Pages/Tickets";
import Anpr from "../Pages/Anpr";
import CC from "../Pages/CC";
import Sessions from "../Pages/Sessions";
import Backups from "../Pages/Backups";
import CodeJson from "../Pages/CodeJson"
//// MAIN PAGES 
import Customers from "../Pages/Customers";
import Orders from "../Pages/Orders";
import Order from "../Pages/Orders/order";



// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";
import LicenceCreate from "../Pages/LicenceCreate";
import Baneos from "../Pages/Baneos";
import Resume from "../Pages/Resume";

const authProtectedRoutes = [
  //dashboard

  { path: "/resume", component: <Resume /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/:id", component: <Dashboard /> },

  { path: "/customers", component: <Customers /> },
  { path: "/banneos", component: <Baneos /> },
  { path: "/tickets", component: <Tickets /> },
  { path: "/anpr", component: <Anpr /> },
  // { path: "/opers", component: <Cash /> },
  // { path: "/stats", component: <Cash /> },
  { path: "/cash", component: <Cash /> },
  // { path: "/companies", component: <Companies /> },
  { path: "/cc", component: <CC /> },
  { path: "/sessions", component: <Sessions /> },
  { path: "/backups", component: <Backups /> },
  { path: "/configJson", component: <CodeJson /> },
  { path: "/licenceCreate", component: <LicenceCreate /> },
  { path: "/orders", component: <Orders /> },
  { path: "/orders/:id", component: <Order /> },
  { path: "/userprofile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
