import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Row,
  Container,
  CardTitle,
  CardFooter,
  CardText,
  CardImg,
} from "reactstrap";

import { fetchOpenedTickets, setOpenedTickets } from "../../store/data/actions";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import TicketsStats from "./ticketstats";
import { Modal, ModalBody } from "reactstrap";
import styles from "../Anpr/styles.module.css";

function formatLicensePlate(plate) {
  // Expresión regular para separar letras y números
  return plate.replace(
    /([A-Z]+)(\d+)([A-Z]*)/,
    (match, letters1, numbers, letters2) => {
      const parts = [letters1, numbers, letters2].filter(Boolean); // Elimina partes vacías
      return parts.join(" "); // Une las partes con un espacio
    }
  );
}

const Tickets = (props) => {
  const customer_id = props.currentCompany.id;
  const openedTickets = useSelector((state) => state.data.openedTickets);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  // if openedTickets is not array
  // or openedTickets is empty array
  // then return null
  if (!Array.isArray(openedTickets) || !openedTickets.length) {
    return null;
  }

  const getType = (type) => {
    switch (type) {
      case "hour":
        return "xHora";
      case "stay":
        return "xEstadia";
      case "tn":
        return "xTurno";
      default:
        return "--";
    }
  };

  const TimeAgo = ({ date }) => {
    moment.locale("es");
    // const timeAgo = moment(date).fromNow();
    const timeAgo = moment(date).format("DD/MM HH:mm");

    return <span>{timeAgo}</span>;
  };

  const getColor = (colorName, opacity) => {
    console.log(colorName);
    const colors = {
      blue: [11, 177, 151],

      indigo: [86, 74, 177],
      purple: [86, 100, 210],

      pink: [232, 62, 140],
      red: [255, 61, 96],
      stay: [255, 61, 96],
      orange: [241, 115, 79],
      yellow: [252, 185, 44],
      tn: [252, 185, 44],
      teal: [5, 5, 5],
      cyan: [74, 163, 255],

      stay: [50, 191, 83],
      tn: [224, 25, 25],
      hour: [13, 122, 246],
    };

    const color = colors[colorName.toLowerCase()];

    if (!color) {
      console.warn(`Color '${colorName}' not found. Defaulting to black.`);
      return `rgba(0, 0, 0, ${opacity})`; // Default to black if color is not found
    }

    return `rgba(${color[0]}, ${color[1]}, ${color[2]},.2)`;
  };

  return (
    <React.Fragment>
      <Row className="g-3">
        {" "}
        {/* Añade un gap entre columnas */}
        {openedTickets.map((item, index) => {
          const bgcolor = getColor(item.type, 0.3);
          return (
            <Col
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              xxl={2}
              key={index}
              style={{
                gap: "1rem",
              }}
            >
              <Card
                className={`${styles.card} mb-4 shadow-lg`}
                style={{ height: "100%" }}
              >
                <div className={styles["image-container"]}>
                  {item.picture && (
                    <CardImg
                      top
                      src={`data:image/png;base64,${item.picture}`}
                      alt={`Imagen del vehículo con matrícula ${item.plate}`}
                      className={styles["card-img"]}
                      onClick={() =>
                        openModal(`data:image/png;base64,${item.picture}`)
                      }
                    />
                  )}
                </div>
                <CardBody
                  className={`${styles["card-body"]}`}
                  style={{ backgroundColor: bgcolor }}
                >
                  <CardTitle tag="h3" className={styles["cardTitle"]}>
                    <strong>{formatLicensePlate(item.plate)}</strong>
                  </CardTitle>

                  <div className={styles["card-category"]}>
                    {item.category_name}
                  </div>
                  <div className={styles["card-type"]}>
                    {getType(item.type)}
                  </div>
                  <div className={styles["card-car-details"]}>
                    {item.car_detail}
                  </div>

                  {item.obs && (
                    <CardText
                      className="text-center mt-2"
                      style={{ backgroundColor: "yellow", borderRadius: 5 }}
                    >
                      {item.obs}
                    </CardText>
                  )}
                </CardBody>
                {/* FOOTER */}

                {/* Footer del Card */}
                <CardFooter className={styles["card-footer"]}>
                  <TimeAgo date={item.init_date} />
                  <div>
                    <small>Abierto por {item.fullName.toUpperCase()}</small>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        size="lg"
        centered
        keyboard={true}
        backdrop={true}
      >
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ padding: 0 }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="ticket full-size"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "60vh",
                width: "auto",
                height: "auto",
                zoom: 1.5,
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    openedTickets: state.data.openedTickets, // data es la propiedad en el estado de Redux donde almacenas los datos
    customer_id: state.data.customer_id,
    currentCompany: state.companies.currentCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOpenedTickets: () => dispatch(fetchOpenedTickets()),
    setOpenedTickets: () => dispatch(setOpenedTickets()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
