import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import ItemAnpr from "./item";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TicketReferences from "./referenceWidget";

const filterTicketsByDate = (tickets) => {
  const now = new Date(); // Fecha actual
  return tickets.filter((ticket) => {
    const entryDate = new Date(ticket.init_date); // Convertir fecha de ingreso
    const differenceInTime = now - entryDate; // Diferencia en milisegundos
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24); // Convertir a días
    return differenceInDays <= 7; // Filtrar tickets con antigüedad <= 7 días
  });
};

const Anpr = (props) => {
  // Estados para la búsqueda y el filtro
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("all");

  // Merge de los tickets abiertos y cerrados
  const tickets = filterTicketsByDate([...props.openedTickets, ...props.closedTickets]);

  // Función para filtrar y buscar
  const filteredTickets = tickets.filter((ticket) => {
    const matchesSearch =
      searchText === "" ||
      ticket.plate.toLowerCase().includes(searchText.toLowerCase());

    const matchesFilter =
      filter === "all" ||
      (filter === "open" && ticket.closed === 0) ||
      (filter === "closed" && ticket.closed === 1 && ticket.canceled !== 1) ||
      (filter === "canceled" && ticket.canceled === 1) ||
      (filter === "closed-canceled" &&
        (ticket.closed === 1 || ticket.canceled === 1));

    return matchesSearch && matchesFilter;
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TicketControl" breadcrumbItem="ANPR" />

          <div>
            <Row className="align-items-center">
              {/* Barra de herramientas */}
              <Col>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label for="search">Buscar por Matrícula</Label>
                      <Input
                        id="search"
                        type="text"
                        placeholder="Ejemplo: ABC123"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label for="filter">Filtrar Tickets</Label>
                      <Input
                        id="filter"
                        type="select"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      >
                        <option value="all">Todos</option>
                        <option value="open">Solo Abiertos</option>
                        <option value="closed">Solo Cerrados</option>
                        <option value="canceled">Solo Cancelados</option>
                        <option value="closed-canceled">
                          Cerrados y Cancelados
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              {/* Referencias */}
              <Col className="pt-3">
                {/* <TicketReferences /> */}
              </Col>
            </Row>

            {/* Grilla de tickets */}
            <Row className="g-3 gy-0 mt-3">
              {filteredTickets.map((ticket) => {
                if (ticket.picture) {
                  return (
                    <Col key={ticket.id} md="4" lg="4" xl="3" xxl="2">
                      <ItemAnpr ticket={ticket} />
                    </Col>
                  );
                }
                return null;
              })}
            </Row>
          </div>
          <p className="card-title-desc">
            * Se muestran las imágenes de los tickets con una antigüedad máxima
            de 7 días.
          </p>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    closedTickets: state.data.closedTickets, // data es la propiedad en el estado de Redux donde almacenas los datos
    openedTickets: state.data.openedTickets,
    customer_id: state.data.customer_id,
    currentCompany: state.companies.currentCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Anpr);
